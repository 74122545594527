@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
body {
  margin: 0;
  font-family: 'Work Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Work Sans';
}

/* Calendar header */
.rbc-header {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: 500;
  font-size: 13px;
  min-height: 55px;
  color: #1600ff;
  border-bottom: 1px solid #ddd;
}
.rbc-header + .rbc-header {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-timeslot-group {
  min-height: 50px;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  border: none;
  min-height: 0;
}

.rbc-time-slot {
  flex: 1 0;
  justify-content: center;
  align-items: center;
  display: flex;
}
/*All day row*/
.rbc-time-view .rbc-allday-cell {
  display: none;
}

/*Header row*/
.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex-basis: 0 px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Work Sans';
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border: none;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px dotted #ddd;
}

.rbc-time-content > .rbc-day-slot {
  background: white;
}

.rbc-label {
  color: #666666;
  font-style: normal;
  font-weight: 400;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  border: none;
}

.rbc-time-content {
  border-top: 1px solid #ddd;
}

.rbc-time-header.rbc-overflowing {
  margin-right: -2px !important;
}

.rbc-time-header.rbc-overflowing {
  border-right: none;
}

.addBorder {
  border-left: 1px solid #ddd;
  margin-right: 3px;
}

.removeBorder {
  border: none;
}

#time_column {
  border-top: 1px solid #ddd;
  margin-top: 55px;
  height: 615px;
}

.rbc-day-slot .rbc-event-label {
  display: none !important;
  margin-top: 11px;
  flex: none;
  padding-right: 5px;
  width: auto;
}

.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
  margin-top: 3px;
  font-size: 14.5px;
}

.rbc-day-slot .rbc-events-container{
  margin-right: 2.5%;
  margin-left: 2.5%;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #1600ff;
  pointer-events: none;
}

/*Date Picker*/
.react-date-picker * {
  font-family: 'Poppins';
}
.react-date-picker__inputGroup {
  display: none;
}

.react-date-picker__wrapper {
  display: none !important;
}

/*Rich Text*/
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border: none;
  margin-top: 15px;
}
.ck.ck-editor__main {
  height: 315px;
}
.ck.ck-editor__editable_inline {
  border: none !important;
  border-top: 1px solid transparent !important;
}
.ck.ck-editor__editable {
  max-height: 310px;
}

/* Calendar month */
.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  background: #f45353 !important;
}
.Calendar {
  width: 100% !important;
  box-shadow: none !important;
}

.Calendar__monthText {
  color: #1600ff;
  font-style: normal;
  font-weight: 600;
  font-size: 32px !important;
  line-height: 130%;
}

.Calendar__yearText {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #8f8f8f;
}

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background: #f7f7f7 !important;
  border-radius: 16px !important;
  border-radius: 0 px;
  border-color: transparent;
}

.Calendar__header {
  border-bottom: 1px solid #e0e0e0;
}

.Calendar__yearSelectorWrapper {
  margin-top: 10px !important;
}

.Calendar__monthSelectorAnimationWrapper {
  margin-top: 10px !important;
}

.Calendar__section {
  padding: 0 4.2em !important;
}

.Calendar__weekDays {
  margin-top: 25px !important;
  padding: 0 3.5em !important;
}

.Calendar__monthArrow {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34.828' height='18.414' viewBox='0 0 34.828 18.414'%3E%3Cpath id='Path_7' data-name='Path 7' d='M1,33,17,17,1,1' transform='translate(0.414 18) rotate(-90)' fill='none' stroke='%23d3d6e4' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A") !important;
  width: 32px !important;
  height: 32px !important;
}

.Calendar__day.-selected {
  padding: 20px 0 !important;
  border: 1.4px solid #f45353 !important;
  box-sizing: border-box !important;
  border-radius: 16px !important;
  background: none !important;
  color: #f45353 !important;
}

.Calendar__day.-ltr {
  font-size: 18px !important;
  padding: 20px 0 !important;
}

.Calendar__weekDay {
  font-size: 18px !important;
  color: #b8b8b8 !important;
  font-family: 'Poppins' !important;
}

.Calendar__sectionWrapper {
  position: relative;
  min-height: 38em !important;
  overflow: hidden;
  }

